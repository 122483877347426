<template>
    <div v-if="databaseStructure != null">
        <div class="queryBuilderSection query-builder">
            <add-button label="TABLE" @click="addTable" />
            <div class="group-conditions">
                <query-builder-table
                    v-for="(table, index) in values.from"
                    :key="table.vueKey"
                    :table="table"
                    :simple-query-builder="simpleQueryBuilder"
                    @remove="removeTable(index)"
                />
            </div>
        </div>
        <div class="queryBuilderSection query-builder" v-show="values.from.length > 0">
            <add-button label="COLUMN" @click="addColumn" />
            <add-button label="EXPRESSION" @click="addExpression" v-if="!simpleQueryBuilder" />
            <div class="group-conditions">
                <div
                    v-for="(select, index) in values.select"
                    :key="select.vueKey"
                    class="condition"
                >
                    <query-builder-column
                        v-if="!select.isExpression"
                        :tables="values.from"
                        :select="select"
                        :simple-query-builder="simpleQueryBuilder"
                        @remove="removeColumn(index)"
                    />
                    <query-builder-expression
                        v-if="select.isExpression"
                        :select="select"
                        @remove="removeColumn(index)"
                    />
                </div>
            </div>
        </div>
        <div class="queryBuilderSection" v-show="values.select.length > 0 && !simpleQueryBuilder">
            <add-button v-show="!isDistinct" label="DISTINCT" @click="addDistinct" />
            <div v-show="isDistinct">
                <div class="form-inline">
                    <remove-button label="DISTINCT" @click="removeDistinct" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-plusplus */
import Vue from "vue";
import QueryBuilderTable from "./rules/QueryBuilderTable.vue";
import QueryBuilderColumn from "./rules/QueryBuilderColumn.vue";
import QueryBuilderExpression from "./rules/QueryBuilderExpression.vue";
import QueryBuilderAddButton from "./buttons/QueryBuilderAddButton.vue";
import QueryBuilderRemoveButton from "./buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderSelect",
    props: ["values", "simpleQueryBuilder"],
    components: {
        QueryBuilderTable,
        QueryBuilderColumn,
        QueryBuilderExpression,
        "add-button": QueryBuilderAddButton,
        "remove-button": QueryBuilderRemoveButton,
    },
    data() {
        return {
            vueKeyCounter: 0,
            isDistinct: false,
        };
    },
    computed: {
        databaseStructure() {
            return this.$store.state.DashboardStore.editorDatabaseStructure;
        },
    },
    created() {
        $.each(this.values.from, (index, item) => {
            item.vueKey = this.vueKeyCounter++;
        });

        $.each(this.values.select, (index, item) => {
            item.vueKey = this.vueKeyCounter++;
        });

        if (this.values.distinct) {
            this.isDistinct = true;
        }
    },
    methods: {
        addTable() {
            this.values.from.push({
                name: "",
                alias: "",
                displayText: "",
                vueKey: this.vueKeyCounter++,
            });
        },
        removeTable(index) {
            this.values.from.splice(index, 1);
        },
        addColumn() {
            if (this.values.from.length > 0) {
                const table = this.$store.getters["DashboardStore/getTable"](
                    this.values.from[0].name
                );
                if (table != null && table.columns) {
                    this.values.select.push({
                        table: this.values.from[0],
                        column: `\`${table.columns[0]}\``,
                        aggregate: "NONE",
                        alias: "",
                        isExpression: false,
                        vueKey: this.vueKeyCounter++,
                    });
                }
            }
        },
        addExpression() {
            this.values.select.push({
                expression: "",
                alias: "",
                isExpression: true,
                vueKey: this.vueKeyCounter++,
            });
        },
        removeColumn(index) {
            this.values.select.splice(index, 1);
        },
        addDistinct() {
            if (!this.values.distinct) {
                Vue.set(this.values, "distinct", true);
                this.isDistinct = true;
            }
        },
        removeDistinct() {
            Vue.delete(this.values, "distinct");
            this.isDistinct = false;
        },
    },
};
</script>
