<template>
    <div
        class="query-builder-query"
        :class="{ querySection: !simpleQueryBuilder }"
        v-if="databaseStructure != null"
    >
        <query-builder-select :values="values" :simple-query-builder="simpleQueryBuilder" />
        <query-builder-where
            :values="values.where"
            :all-columns="allColumns"
            :selected-columns="selectedColumns"
            v-show="values.select.length > 0"
        />
        <query-builder-filter
            :values="values"
            :selected-columns="selectedColumns"
            v-show="!simpleQueryBuilder && values.select.length > 0"
        />
    </div>
</template>

<script>
import QueryBuilderSelect from "./QueryBuilderSelect.vue";
import QueryBuilderWhere from "./QueryBuilderWhere.vue";
import QueryBuilderFilter from "./QueryBuilderFilter.vue";

export default {
    name: "QueryBuilderQuery",
    props: ["values", "simpleQueryBuilder"],
    components: {
        QueryBuilderSelect,
        QueryBuilderWhere,
        QueryBuilderFilter,
    },
    computed: {
        databaseStructure() {
            return this.$store.state.DashboardStore.editorDatabaseStructure;
        },
        allColumns() {
            const allColumns = [];
            const usedNames = {};
            this.values.from.forEach((item) => {
                const table = this.$store.getters["DashboardStore/getTable"](item.name);
                if (table) {
                    table.columns.forEach((column) => {
                        let columnDef;
                        if (item.alias) {
                            columnDef = {
                                name: `${item.alias}.\`${column}\``,
                                displayText: `${item.alias}.\`${column}\``,
                            };
                        } else {
                            columnDef = {
                                name: `${item.name}.\`${column}\``,
                                displayText: `${item.displayText}.\`${column}\``,
                            };
                        }
                        if (!usedNames[columnDef.name]) {
                            allColumns.push(columnDef);
                            usedNames[columnDef.name] = true;
                        }
                    });
                }
            });
            return allColumns;
        },
        selectedColumns() {
            const selectedColumns = [];
            const usedNames = {};
            this.values.select.forEach((item) => {
                if (item.alias) {
                    if (!usedNames[item.alias]) {
                        selectedColumns.push({ tableName: item.alias, displayText: item.alias });
                        usedNames[item.alias] = true;
                    }
                } else {
                    if (item.table && item.column) {
                        if (item.table.alias) {
                            const tableName = `${item.table.alias}.${item.column.toLowerCase()}`;
                            if (!usedNames[tableName]) {
                                selectedColumns.push({
                                    tableName,
                                    displayText: `${item.table.alias}.${item.column}`,
                                });
                                usedNames[tableName] = true;
                            }
                        } else {
                            const tableName = `${item.table.name}.${item.column.toLowerCase()}`;
                            if (!usedNames[tableName]) {
                                selectedColumns.push({
                                    tableName,
                                    displayText: `${item.table.displayText}.${item.column}`,
                                });
                                usedNames[tableName] = true;
                            }
                        }
                    }
                    if (item.expression && !usedNames[item.expression]) {
                        selectedColumns.push({
                            tableName: item.expression,
                            displayText: item.expression,
                        });
                        usedNames[item.expression] = true;
                    }
                }
            });
            return selectedColumns;
        },
    },
};
</script>
