<template>
    <div
        class="query-builder"
        :class="{ querySection: !simpleQueryBuilder, simpleQueryBuilder: simpleQueryBuilder }"
    >
        <div class="form-inline" v-show="!simpleQueryBuilder">
            <div class="form-inline-title">
                {{ $t("SECTION") }}
            </div>
            <div class="select-wrapper select-arrow-down">
                <select v-model="values.operator" class="generic-select form-control input-sm">
                    <option value="" disabled>{{ $t("OPERATOR") }}</option>
                    <option
                        v-for="operator in operators"
                        :key="operator.name"
                        :value="operator.name"
                    >
                        {{ $t(operator.displayText) }}
                    </option>
                </select>
            </div>
            <add-button label="QUERY" @click="addQuery" />
            <add-button label="SECTION" @click="addSection" />
            <remove-button v-show="canRemove" @click="$emit('removeSection')" />
        </div>

        <div :class="{ 'group-conditions': !simpleQueryBuilder }">
            <div v-for="(rule, index) in values.queries" class="condition" :key="index">
                <div v-if="rule.type === 'setOperation'">
                    <query-builder-directive
                        :canRemove="true"
                        :values="rule"
                        :simple-query-builder="simpleQueryBuilder"
                        @removeSection="handleRemove(index)"
                    />
                </div>
                <div v-else>
                    <div class="form-inline condition-padding-bottom">
                        <div class="form-inline-title">{{ $t("QUERY") }}</div>
                        <query-builder-query
                            :values="rule"
                            :simple-query-builder="simpleQueryBuilder"
                        />
                        <remove-button v-show="!simpleQueryBuilder" @click="handleRemove(index)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/helpers/evaluation/util";
import QueryBuilderQuery from "./QueryBuilderQuery.vue";
import QueryBuilderAddButton from "./buttons/QueryBuilderAddButton.vue";
import QueryBuilderRemoveButton from "./buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderDirective",
    mixins: [util],
    props: ["values", "canRemove", "simpleQueryBuilder"],
    components: {
        QueryBuilderQuery,
        "add-button": QueryBuilderAddButton,
        "remove-button": QueryBuilderRemoveButton,
    },
    data() {
        return {
            operators: [
                { name: "UNION", displayText: "UNION" },
                { name: "UNION_ALL", displayText: "UNION ALL" },
                { name: "INTERSECT", displayText: "INTERSECT" },
                { name: "MINUS", displayText: "MINUS" },
            ],
        };
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            if (this.isEmptyObject(this.values)) {
                this.values.type = "setOperation";
                this.values.operator = "UNION";
                this.values.queries = [];
            }
            if (this.simpleQueryBuilder && this.values.queries.length <= 0) {
                this.addQuery();
            }
        },
        addQuery() {
            this.values.queries.push({
                type: "query",
                where: {
                    type: "booleanOperation",
                    operation: "AND",
                    value: [],
                },
                from: [],
                select: [],
                group: [],
                order: [],
            });
        },
        addSection() {
            this.values.queries.push({
                type: "setOperation",
                operator: "UNION",
                queries: [],
            });
        },
        handleRemove(index) {
            this.values.queries.splice(index, 1);
        },
    },
};
</script>
