<template>
    <button type="button" @click="$emit('click')" class="query-button">
        <svg
            class="add-button"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 56 56"
            style="enable-background: new 0 0 56 56"
            xml:space="preserve"
        >
            <path
                class="st0"
                d="M1,24.5h54c0.5,0,1,0.4,1,1v5c0,0.6-0.5,1-1,1H1c-0.6,0-1-0.4-1-1l0-5C0,24.9,0.4,24.5,1,24.5z"
            />
            <path
                class="st0"
                d="M24.5,55V1c0-0.5,0.4-1,1-1l5,0c0.6,0,1,0.4,1,1v54c0,0.5-0.4,1-1,1h-5C24.9,56,24.5,55.5,24.5,55z"
            />
        </svg>
        &nbsp;
        <span>{{ $t(label) }}</span>
    </button>
</template>

<script>
export default {
    name: "QueryBuilderAddButton",
    props: ["label"],
};
</script>
