<template>
    <form>
        <div class="axis-property">
            <div class="axis-property-select">
                <label for="axis-label"> {{ $t("LABEL") }} </label>
                <Dropdown
                    class="axis-property-dropdown"
                    compact
                    v-model="$v.axisProperty.label.$model"
                    placeholder="Label"
                    :searchAble="axisLabelsValues.length > 8"
                    :options="axisLabelsValues"
                    :visibleItems="8"
                />
                <span
                    class="widgetWizardErrorMessage"
                    v-show="showErrors && !$v.axisProperty.label.required"
                    >{{ $t("REQUIRED") }}</span
                >
            </div>
        </div>
        <div class="axis-property-select">
            <label for="column">
                {{ $t("COLUMN") }}
            </label>
            <div class="select-arrow-down">
                <select
                    id="column"
                    name="columnIndex"
                    v-model="$v.axisProperty.columnIndex.$model"
                    class="generic-select form-control input-sm"
                    required
                >
                    <option :value="null" disabled>{{ $t("COLUMN") }}</option>
                    <option v-for="(c, i) in columns" :key="i" :value="c.index">
                        {{ $t(c.name) }}
                    </option>
                </select>
            </div>
            <span
                class="widgetWizardErrorMessage"
                v-show="showErrors && !$v.axisProperty.columnIndex.required"
                >{{ $t("REQUIRED") }}</span
            >
        </div>
        <div
            v-show="widgetTemplateType === 'GRAPH' && axisProperty.axis === 'Y'"
            class="axis-property-select"
        >
            <label for="display-type">
                {{ $t("DISPLAY_TYPE") }}
            </label>

            <div class="select-arrow-down">
                <select
                    id="display-type"
                    name="displayType"
                    v-model="$v.axisProperty.displayType.$model"
                    class="generic-select form-control input-sm"
                >
                    <option :value="null" disabled>{{ $t("DISPLAY_TYPE") }}</option>
                    <option v-for="(dType, i) in displayTypes" :key="i" :value="dType">
                        {{ $t(dType) }}
                    </option>
                </select>
            </div>
            <span
                class="widgetWizardErrorMessage"
                v-show="showErrors && !$v.axisProperty.displayType.displayTypeRequired"
                >{{ $t("REQUIRED") }}</span
            >
        </div>
        <div
            v-if="
                axisProperty.axis === 'Y' ||
                widgetTemplateType === 'SINGLE_VALUE' ||
                widgetTemplateType === 'GAUGE' ||
                widgetTemplateType === 'MOOD_MONITOR_GAUGE'
            "
            class="axis-property-select"
        >
            <label for="numberOfDecimals">
                {{ $t("NUMBER_OF_DECIMALS") }}
            </label>

            <div class="select-arrow-down">
                <select
                    id="numberOfDecimals"
                    name="numberOfDecimals"
                    v-model="$v.axisProperty.numberOfDecimals.$model"
                    class="generic-select form-control input-sm"
                    required
                >
                    <option :value="null" disabled>{{ $t("NUMBER_OF_DECIMALS") }}</option>
                    <option v-for="(decimal, i) in availableDecimals" :key="i" :value="decimal">
                        {{ decimal }}
                    </option>
                </select>
            </div>
            <span
                class="widgetWizardErrorMessage"
                v-show="showErrors && !$v.axisProperty.numberOfDecimals.numberOfDecimalsRequired"
                >{{ $t("REQUIRED") }}</span
            >
        </div>

        <div
            v-if="
                axisProperty.axis === 'Y' &&
                axisProperty.displayType !== 'PIE_CHART' &&
                widgetTemplateType === 'GRAPH'
            "
            class="axis-property-select axis-color-select"
        >
            <label for="axisColor">{{ $t("COLOR") }}</label>

            <el-select
                class="color-picker-caret-tevreden-blue"
                v-model="$v.axisColorId.$model"
                :placeholder="$t('CHOOSE_COLOR')"
                :disabled="!changeColorEnabled"
                @change="colorChanged"
                filterable
            >
                <el-option
                    v-for="color in axisColors"
                    :key="color.id"
                    :label="$t(color.name)"
                    :value="color.id"
                >
                    <div class="color-box" :style="{ background: color.hex }"></div>
                    <div>{{ $t(color.name) }}</div>
                </el-option>
            </el-select>
            <span
                class="widgetWizardErrorMessage"
                v-show="showErrors && !$v.axisColorId.colorRequired"
                >{{ $t("REQUIRED") }}</span
            >
        </div>
    </form>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { Dropdown } from "@feedbackcompany/feedback-company-vue-components";

export default {
    name: "AxisProperty",
    mixins: [validationMixin],
    components: {
        Dropdown,
    },
    props: [
        "axisProperty",
        "axisLabels",
        "axisColors",
        "widgetTemplateType",
        "columns",
        "limitDisplayTypes",
        "isBenchmark",
        "showErrors",
    ],
    data() {
        return {
            axisColorId: "",
            possibleAxis: ["X", "Y"],
            possibleDisplayTypes: [
                "LINE_CHART",
                "BAR_CHART",
                "COLUMN_CHART",
                "PIE_CHART",
                "SCATTER_CHART",
                "BAR_STACKED",
                "COLUMN_STACKED",
            ],
            possibleDisplayTypesLimited: [
                "LINE_CHART",
                "BAR_CHART",
                "COLUMN_CHART",
                "SCATTER_CHART",
                "BAR_STACKED",
                "COLUMN_STACKED",
            ],
            availableDecimals: [0, 1, 2],
        };
    },
    computed: {
        displayTypes() {
            if (this.limitDisplayTypes) {
                return this.possibleDisplayTypesLimited;
            }
            return this.possibleDisplayTypes;
        },
        changeColorEnabled() {
            return (
                !this.isBenchmark ||
                this.axisProperty.displayType === "BAR_STACKED" ||
                this.axisProperty.displayType === "COLUMN_STACKED"
            );
        },
        displayType() {
            return this.axisProperty.displayType;
        },
        axisColor() {
            return this.axisProperty.axisColor;
        },
        axisLabelsValues() {
            return this.axisLabels.map((element) => ({
                value: element.labelName,
                displayValue: element.translatedLabelName,
            }));
        },
    },
    validations() {
        const displayTypeRequired = (value) => {
            if (
                this.widgetTemplateType &&
                this.axisProperty &&
                this.axisProperty.axis === "Y" &&
                !value
            ) {
                if (this.isBenchmark && !this.limitDisplayTypes) {
                    return true;
                }
                return false;
            }
            return true;
        };

        const numberOfDecimalsRequired = (value) =>
            this.axisProperty &&
            (this.axisProperty.axis === "Y" ||
                this.widgetTemplateType === "SINGLE_VALUE" ||
                this.widgetTemplateType === "GAUGE" ||
                this.widgetTemplateType !== "MOOD_MONITOR_GAUGE")
                ? helpers.req(value)
                : true;

        const colorRequired = (value) =>
            this.axisProperty &&
            this.axisProperty.axis === "Y" &&
            this.axisProperty.displayType !== "PIE_CHART" &&
            this.widgetTemplateType === "GRAPH"
                ? helpers.req(value)
                : true;

        return {
            axisProperty: {
                label: { required },
                columnIndex: { required },
                displayType: { displayTypeRequired },
                numberOfDecimals: { numberOfDecimalsRequired },
            },
            axisColorId: { colorRequired },
        };
    },
    created() {
        this.axisColorId = this.axisProperty.axisColor ? this.axisProperty.axisColor.id : "";
    },
    methods: {
        colorChanged() {
            $.each(this.axisColors, (index, axisColor) => {
                if (axisColor.id === this.axisColorId) {
                    this.axisProperty.axisColor = axisColor;
                    return false;
                }
            });
            this.$emit("setColorForAxisProperties");
        },
        isValid() {
            return !this.$v.$invalid;
        },
    },
    watch: {
        displayType(newValue, oldValue) {
            if (
                newValue !== oldValue &&
                (oldValue === "BAR_STACKED" ||
                    oldValue === "COLUMN_STACKED" ||
                    newValue === "BAR_STACKED" ||
                    newValue === "COLUMN_STACKED")
            ) {
                this.$emit("setDisplayTypeForAxisProperties", newValue);
            }
        },
        axisColor() {
            this.axisColorId = this.axisProperty.axisColor ? this.axisProperty.axisColor.id : "";
        },
    },
};
</script>
