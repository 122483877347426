<template>
    <div class="form-inline">
        <div class="form-inline-title">{{ $t("EXPRESSION") }}</div>
        <input
            type="text"
            v-model="select.expression"
            class="form-control input-sm expression"
            :placeholder="$t('EXPRESSION')"
        />
        <input
            type="text"
            v-model="select.alias"
            class="form-control input-sm"
            :placeholder="$t('ALIAS')"
        />
        <remove-button @click="$emit('remove')" />
    </div>
</template>

<script>
import QueryBuilderRemoveButton from "../buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderExpression",
    props: ["select"],
    components: {
        "remove-button": QueryBuilderRemoveButton,
    },
};
</script>
