<template>
    <div>
        <div class="queryBuilderSection query-builder">
            <add-button label="GROUP_BY" @click="addGroupBy" class="query-button-margin-bottom" />
            <div class="group-conditions custom-group-condition" v-show="values.group.length > 0">
                <query-builder-group-by
                    v-for="(groupBy, index) in groupBys"
                    :key="groupBy.vueKey"
                    :value="groupBy.value"
                    :selected-columns="selectedColumns"
                    @update="updateGroupBy($event, index)"
                    @remove="removeGroupBy(index)"
                />
            </div>
        </div>

        <div class="queryBuilderSection query-builder">
            <add-button label="ORDER_BY" @click="addOrderBy" class="query-button-margin-bottom" />
            <div class="group-conditions custom-group-condition" v-show="values.order.length > 0">
                <query-builder-order-by
                    v-for="(item, index) in values.order"
                    :key="item.vueKey"
                    :order-by="item"
                    :selected-columns="selectedColumns"
                    @remove="removeOrderBy(index)"
                />
            </div>
        </div>

        <div class="queryBuilderSection query-builder">
            <add-button
                v-show="!limitSet"
                label="LIMIT"
                @click="addLimit"
                class="query-button-margin-bottom"
            />
            <div v-show="limitSet" style="margin-bottom: 20px">
                <div class="form-inline">
                    <div class="form-inline-title">{{ $t("LIMIT") }}</div>
                    <input
                        type="number"
                        min="0"
                        :placeholder="$t('VALUE')"
                        v-model="values.limit"
                        class="form-control input-sm"
                    />
                    <remove-button @click="removeLimit" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-plusplus */
import Vue from "vue";

import QueryBuilderGroupBy from "./rules/QueryBuilderGroupBy.vue";
import QueryBuilderOrderBy from "./rules/QueryBuilderOrderBy.vue";
import QueryBuilderAddButton from "./buttons/QueryBuilderAddButton.vue";
import QueryBuilderRemoveButton from "./buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderFilter",
    props: ["values", "selectedColumns"],
    components: {
        QueryBuilderGroupBy,
        QueryBuilderOrderBy,
        "add-button": QueryBuilderAddButton,
        "remove-button": QueryBuilderRemoveButton,
    },
    data() {
        return {
            limitSet: false,
            vueKeyCounter: 0,
            groupBys: [], // this separate list is used because of vue keys
        };
    },
    created() {
        $.each(this.values.group, (index, item) => {
            this.groupBys.push(this.createGroupBy(item));
        });

        $.each(this.values.order, (index, item) => {
            item.vueKey = this.vueKeyCounter++;
        });

        if (this.values.limit) {
            this.limitSet = true;
        }
    },
    methods: {
        createGroupBy(value) {
            return { value, vueKey: this.vueKeyCounter++ };
        },
        addGroupBy() {
            const value = this.selectedColumns[0].tableName;
            this.values.group.push(value);
            this.groupBys.push(this.createGroupBy(value));
        },
        updateGroupBy(value, index) {
            Vue.set(this.values.group, index, value);
            this.groupBys[index].value = value;
        },
        removeGroupBy(index) {
            this.values.group.splice(index, 1);
            this.groupBys.splice(index, 1);
        },
        addOrderBy() {
            this.values.order.push({
                column: this.selectedColumns[0].tableName,
                direction: "ASC",
                vueKey: this.vueKeyCounter++,
            });
        },
        removeOrderBy(index) {
            this.values.order.splice(index, 1);
        },
        addLimit() {
            if (!this.limitSet) {
                Vue.set(this.values, "limit", 1);
                this.limitSet = true;
            }
        },
        removeLimit() {
            Vue.delete(this.values, "limit");
            this.limitSet = false;
        },
    },
};
</script>
