<template>
    <div class="form-inline">
        <div class="form-inline-title">{{ $t("CONDITION") }}</div>

        <div class="select-wrapper select-arrow-down">
            <select
                v-model="selectedColumn"
                @change="columnChanged"
                class="generic-select form-control input-sm"
            >
                <option value="" disabled>{{ $t("COLUMN") }}</option>
                <option
                    v-for="column in allColumns"
                    :key="columnValue(column.name)"
                    :value="columnValue(column.name)"
                >
                    {{ column.displayText }}
                </option>
            </select>
        </div>

        <div class="select-wrapper select-arrow-down">
            <select v-model="rule.operator" class="generic-select form-control input-sm">
                <option value="" disabled>{{ $t("CONDITION") }}</option>
                <option v-for="(condition, i) in conditions" :key="i" :value="condition.name">
                    {{ condition.name }}
                </option>
            </select>
        </div>

        <input
            type="text"
            v-model="rule.value"
            class="form-control input-sm"
            :placeholder="$t('VALUE')"
        />
        <remove-button @click="$emit('remove')" />
    </div>
</template>

<script>
import QueryBuilderRemoveButton from "../buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderCondition",
    props: ["rule", "allColumns"],
    components: {
        "remove-button": QueryBuilderRemoveButton,
    },
    data() {
        return {
            selectedColumn: "",
            conditions: [
                { name: "=" },
                { name: "<>" },
                { name: "<" },
                { name: "<=" },
                { name: ">" },
                { name: ">=" },
            ],
        };
    },
    created() {
        if (
            this.rule.column &&
            this.rule.column !== "" &&
            this.getColumnName(this.columnValue(this.rule.column)) !== ""
        ) {
            this.selectedColumn = this.columnValue(this.rule.column);
        }
    },
    methods: {
        columnValue(columnName) {
            return columnName ? columnName.toLowerCase() : columnName;
        },
        columnChanged() {
            this.rule.column = this.getColumnName(this.selectedColumn);
        },
        getColumnName(value) {
            let columnName = "";
            $.each(this.allColumns, (index, column) => {
                if (this.columnValue(column.name) === value) {
                    columnName = column.name;
                    return false;
                }
            });
            return columnName;
        },
    },
};
</script>
