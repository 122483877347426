<template>
    <div v-if="widgetTemplateOption != null">
        <div v-show="widgetTemplateType === 'GAUGE' || widgetTemplateType === 'MOOD_MONITOR_GAUGE'">
            <div class="input-group available-for">
                <label>{{ $t(widgetTemplateOption.key) }}</label>
                <div>
                    <input
                        type="radio"
                        :id="'yes-' + widgetTemplateOption.key"
                        :value="true"
                        v-model="widgetTemplateOption.value"
                    />
                    <label class="labelNormalText" :for="'yes-' + widgetTemplateOption.key">
                        <span>{{ $t("YES") }}</span>
                    </label>
                </div>
                <div>
                    <input
                        type="radio"
                        :id="'no-' + widgetTemplateOption.key"
                        :value="false"
                        v-model="widgetTemplateOption.value"
                    />
                    <label class="labelNormalText" :for="'no-' + widgetTemplateOption.key">
                        <span>{{ $t("NO") }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WidgetTemplateOption",
    props: ["widgetTemplateType", "widgetTemplateOption"],
};
</script>
