<template>
    <div class="form-inline">
        <div class="form-inline-title">{{ $t("COLUMN") }}</div>
        <div class="select-arrow-down">
            <select
                v-model="selectedTableValue"
                @change="tableChanged"
                class="generic-select form-control input-sm small-resolution-field"
            >
                <option value="" disabled>{{ $t("TABLE") }}</option>
                <option v-for="table in tables" :key="tableValue(table)" :value="tableValue(table)">
                    {{ displayTableName(table) }}
                </option>
            </select>
        </div>
        <div v-if="selectedTable != null && selectedTable.columns">
            <Dropdown
                class="axis-property-dropdown select-column-dropdown"
                compact
                v-model="selectedColumn"
                placeholder="Column"
                :searchAble="columnsList.length > 8"
                :options="columnsList"
                :visibleItems="8"
            />
        </div>
        <div v-show="select.column && !simpleQueryBuilder">
            <input
                type="text"
                v-model="select.alias"
                class="form-control input-sm"
                :placeholder="$t('ALIAS')"
            />
        </div>
        <div class="select-wrapper select-arrow-down" v-show="select.column && !simpleQueryBuilder">
            <select
                v-model="select.aggregate"
                class="margin-left-btn generic-select form-control input-sm"
            >
                s
                <option value="" disabled>{{ $t("FUNCTION") }}</option>
                <option v-for="(aggregate, i) in aggregates" :key="i" :value="aggregate.name">
                    {{ aggregate.name }}
                </option>
            </select>
        </div>
        <remove-button @click="$emit('remove')" />
    </div>
</template>

<script>
import { Dropdown } from "@feedbackcompany/feedback-company-vue-components";
import util from "@/helpers/evaluation/util";
import QueryBuilderRemoveButton from "../buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderColumn",
    props: ["tables", "select", "simpleQueryBuilder"],
    components: {
        "remove-button": QueryBuilderRemoveButton,
        Dropdown,
    },
    data() {
        return {
            selectedTableValue: "",
            selectedColumn: "",
            aggregates: [
                { name: "NONE" },
                { name: "AVG" },
                { name: "SUM" },
                { name: "MIN" },
                { name: "MAX" },
                { name: "FIRST" },
                { name: "LAST" },
                { name: "COUNT" },
                { name: "LEN" },
            ],
        };
    },
    computed: {
        selectedTable() {
            let selectedTable = null;
            if (this.select && this.select.table) {
                selectedTable = this.$store.getters["DashboardStore/getTable"](
                    this.select.table.name
                );
            }
            return selectedTable;
        },
        columnsList() {
            const elements = this.selectedTable.columns.map((element) => ({
                value: this.columnValue(element),
                displayValue: element,
            }));
            return elements.sort(util.methods.objectSortFunc("value"));
        },
    },
    created() {
        this.selectedTableValue = this.tableValue(this.select.table);
        // this is needed because older widget templates don't have lowercased selected column with '`' around it
        this.selectedColumn = this.columnValue(this.select.column);
    },
    methods: {
        displayTableName(table) {
            return table.alias ? table.alias : table.displayText;
        },
        tableValue(table) {
            return `${table.name}-${table.alias}`;
        },
        columnValue(column) {
            let val = column;
            if (val && val.indexOf("`") <= -1) {
                val = `\`${val}\``;
            }
            return val.toLowerCase();
        },
        tableChanged() {
            $.each(this.tables, (index, table) => {
                if (this.selectedTableValue === this.tableValue(table)) {
                    this.select.table = table;
                    return false;
                }
            });
            this.selectedColumn = "";
        },
    },
};
</script>
