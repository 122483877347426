<template>
    <div class="condition custom-condition">
        <div class="form-inline">
            <div class="form-inline-title">{{ $t("GROUP_BY") }}</div>
            <div class="select-wrapper select-arrow-down">
                <select
                    v-model="selectedGroupBy"
                    class="generic-select form-control input-sm"
                    @change="groupByChanged"
                >
                    <option value="" disabled>{{ $t("COLUMN") }}</option>
                    <option
                        v-for="column in selectedColumns"
                        :key="groupByValue(column.tableName)"
                        :value="groupByValue(column.tableName)"
                    >
                        {{ column.displayText }}
                    </option>
                </select>
            </div>
            <remove-button @click="$emit('remove')" />
        </div>
    </div>
</template>

<script>
import QueryBuilderRemoveButton from "../buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderGroupBy",
    props: ["value", "selectedColumns"],
    components: {
        "remove-button": QueryBuilderRemoveButton,
    },
    data() {
        return {
            selectedGroupBy: "",
        };
    },
    created() {
        if (
            this.value &&
            this.value !== "" &&
            this.getColumnValue(this.groupByValue(this.value)) !== ""
        ) {
            this.selectedGroupBy = this.groupByValue(this.value);
        }
    },
    methods: {
        groupByValue(column) {
            return column ? column.toLowerCase() : column;
        },
        groupByChanged() {
            this.$emit("update", this.getColumnValue(this.selectedGroupBy));
        },
        getColumnValue(groupByValue) {
            let columnValue = "";
            $.each(this.selectedColumns, (index, column) => {
                if (this.groupByValue(column.tableName) === groupByValue) {
                    columnValue = column.tableName;
                    return false;
                }
            });
            return columnValue;
        },
    },
};
</script>
