<template>
    <div class="condition">
        <div class="form-inline">
            <div class="select-wrapper select-flex">
                <span class="form-inline-title">{{ $t("TABLE") }}</span>
                <div class="select-arrow-down">
                    <select
                        v-model="selectedTableName"
                        @change="updateTable"
                        class="generic-select form-control input-sm"
                    >
                        <option value="" disabled>{{ $t("TABLE") }}</option>
                        <option
                            v-for="(tbl, index) in databaseStructure.tables"
                            :key="index"
                            :value="tbl.name"
                        >
                            {{ tbl.displayText }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="selectedTable != null && selectedTable.columns && !simpleQueryBuilder">
                <input
                    type="text"
                    v-model="table.alias"
                    class="form-control input-sm"
                    :placeholder="$t('ALIAS')"
                />
            </div>
            <remove-button @click="$emit('remove')" />
        </div>
    </div>
</template>

<script>
import QueryBuilderRemoveButton from "../buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderTable",
    props: ["table", "simpleQueryBuilder"],
    components: {
        "remove-button": QueryBuilderRemoveButton,
    },
    data() {
        return {
            selectedTableName: "",
        };
    },
    computed: {
        databaseStructure() {
            return this.$store.state.DashboardStore.editorDatabaseStructure;
        },
        selectedTable() {
            return this.$store.getters["DashboardStore/getTable"](this.selectedTableName);
        },
    },
    created() {
        this.selectedTableName = this.table ? this.table.name : "";
    },
    methods: {
        updateTable() {
            if (this.selectedTable != null) {
                this.table.name = this.selectedTable.name;
                this.table.displayText = this.selectedTable.displayText;
            }
        },
    },
};
</script>
