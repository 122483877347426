<template>
    <DashboardsModule mode="WidgetEditor">
        <WidgetEditor v-if="!loading" />
    </DashboardsModule>
</template>

<script>
import WidgetEditor from "@/components/Evaluation/Dashboards/views/WidgetEditor.vue";
import DashboardsModule from "@/components/Evaluation/DashboardsModule.vue";
import util from "@/helpers/evaluation/util";
import { widgetService } from "@/services/Evaluation/index";
import clearDashboardsEventListeners from "@/helpers/evaluation/clearDashboardsEventListeners";

export default {
    name: "WidgetEditorBridge",
    mixins: [util],
    components: {
        WidgetEditor,
        DashboardsModule,
    },
    data() {
        return {
            loading: true,
            prevRoute: null,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    async created() {
        if (this.$route.params.id === "new") {
            this.$storage.setItem("widgetToEdit", null);
            this.$storage.setItem("fromWidgetLibrary", true);
            this.loading = false;
        } else if (this.$route.params.id) {
            widgetService
                .getWidgetTemplate(this.$route.params.id)
                .then((response) => {
                    this.$storage.setItem("widgetToEdit", response.data);
                    const isFromWidgetLibrary = this.prevRoute.name === "WidgetTemplates";
                    this.$storage.setItem("fromWidgetLibrary", isFromWidgetLibrary);
                    if (!isFromWidgetLibrary) {
                        this.$storage.setItem("widgetLibraryPrevRoute", this.prevRoute.fullPath);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.displayMessage(this.translate("EDIT_WIDGET_TEMPLATE_ERROR"), "error");
                });
        }
    },
    beforeDestroy() {
        this.$storage.removeItem("widgetToEdit");
        this.$storage.removeItem("fromWidgetLibrary");
        clearDashboardsEventListeners();
    },
    beforeRouteLeave(to, from, next) {
        // This workaround excludes Dashboards specific stylesheets from other pages
        // by refreshing the page entirely when navigating to another page.
        if (["WidgetEditorBridge", "DashboardsBridge", "DashboardsEditBridge"].includes(to.name))
            next();
        else window.location = to.fullPath;
    },
};
</script>
