<template>
    <form>
        <query-builder-directive
            v-show="jsonFormat && !nativeQueryEntered"
            :canRemove="false"
            :values="jsonQuery"
            :simple-query-builder="false"
        />
        <div>
            <div v-show="!nativeQueryEntered">
                <span>{{ $t("SQL_QUERY") }}</span>
                <el-switch
                    v-model="showNativeQuery"
                    active-color="#2e99e5"
                    class="sql-query-switch"
                ></el-switch>
            </div>
            <button
                type="button"
                v-show="nativeQueryEnabled && nativeQueryEntered"
                class="generic-button btn btn-primary reset-to-query-builder"
                @click="resetToQueryBuilder"
            >
                {{ $t("RESET_TO_QUERY_BUILDER") }}
            </button>
            <br />
            <span class="widgetWizardErrorMessage" v-show="showErrors && !nativeQueryValid">{{
                $t("REQUIRED")
            }}</span>
            <textarea
                v-show="showNativeQuery"
                v-model="nativeQuery"
                :disabled="!nativeQueryEnabled"
                @input="nativeQueryChanged"
                class="widgetWizardTextArea benchmarkTextarea"
                style="font-family: monospace; min-width: auto; line-height: 1.6"
            />
        </div>
    </form>
</template>

<script>
import { queryService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";
import QueryBuilderDirective from "./queryBuilder/QueryBuilderDirective.vue";

export default {
    name: "DataSetSection",
    mixins: [util],
    props: ["jsonFormat", "query", "nativeQueryEnabled", "showErrors"],
    components: {
        QueryBuilderDirective,
    },
    data() {
        return {
            nativeQueryEntered: false,
            showNativeQuery: false,
            jsonQuery: {},
            nativeQuery: "",
        };
    },
    computed: {
        nativeQueryValid() {
            return this.nativeQuery && this.nativeQuery !== ";";
        },
        jsonQueryValue() {
            return JSON.stringify(this.jsonQuery);
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            let { query } = this;
            if (!query || this.isEmptyObject(query)) {
                query = '{"type":"setOperation","operator":"UNION","queries":[]}';
                this.$emit("update:query", query);
            }

            // Set values based on jsonFormat and query
            if (this.jsonFormat) {
                this.jsonQuery = JSON.parse(query);
                this.getSqlQuery();
                this.showNativeQuery = false;
            } else {
                this.jsonQuery = {
                    type: "setOperation",
                    operator: "UNION",
                    queries: [],
                };
                this.nativeQuery = query;
                this.showNativeQuery = true;
            }

            this.nativeQueryEntered = false;
        },
        getSqlQuery() {
            queryService
                .getSqlQuery(this.jsonQuery)
                .then((response) => {
                    this.nativeQuery = response.data.query;
                    this.$emit("queryError", false);
                })
                .catch(() => {
                    this.nativeQuery = this.translate("INVALID_SQL_QUERY");
                    this.$emit("queryError", true);
                });
        },
        nativeQueryChanged() {
            this.showNativeQuery = true;
            this.nativeQueryEntered = true;
            this.$emit("update:jsonFormat", false);
            this.$emit("update:query", this.nativeQuery);
        },
        setJsonQuery() {
            this.nativeQueryEntered = false;
            this.$emit("update:jsonFormat", true);
            this.$emit("update:query", JSON.stringify(this.jsonQuery));
        },
        resetToQueryBuilder() {
            this.setJsonQuery();
        },
        isValid() {
            return !this.nativeQueryEnabled || this.nativeQueryValid;
        },
    },
    watch: {
        query() {
            if (this.jsonFormat) {
                this.getSqlQuery();
            }
        },
        nativeQuery() {
            if (this.nativeQueryEnabled && !this.jsonFormat) {
                if (!this.nativeQueryEntered) {
                    this.nativeQueryEntered = true;
                }
                this.nativeQueryChanged();
            }
        },
        jsonQueryValue(newValue, oldValue) {
            if (newValue !== oldValue && this.jsonFormat) {
                this.$emit("update:query", JSON.stringify(this.jsonQuery));
            }
        },
    },
};
</script>
