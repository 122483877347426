<template>
    <form>
        <div v-show="widgetTemplateProperty.benchmark">
            <div class="widgetWizardTextField float-left">
                <label class="display-label">
                    {{ $t("BENCHMARK_QUERY_LABEL_REQUIRED") }}
                </label>
                <input
                    type="text"
                    :class="[
                        { widgetWizardInputError: showErrors && !benchmarkLabelValid },
                        'generic-textfield',
                        'form-control',
                        'benchmark-label',
                    ]"
                    :placeholder="$t('BENCHMARK_QUERY_LABEL')"
                    v-model.trim="widgetTemplateProperty.label"
                    maxlength="32"
                />
                <span
                    class="widgetWizardErrorMessage"
                    v-show="showErrors && !benchmarkLabelValid"
                    >{{ $t("EMPTY_INPUT_FIELD") }}</span
                >
            </div>
            <div class="benchmark-type-select">
                <label for="benchmark-type" class="ww-label">
                    {{ $t("BENCHMARK_TYPE_REQUIRED") }}
                </label>
                <div class="select-wrapper select-arrow-down">
                    <select
                        id="benchmark-type"
                        name="benchmarkType"
                        v-model="widgetTemplateProperty.benchmarkType"
                        @change="benchmarkTypeChanged"
                        class="generic-select form-control input-sm"
                    >
                        <option :value="null" disabled>{{ $t("BENCHMARK_TYPE") }}</option>
                        <option v-for="(bt, i) in benchmarkTypes" :key="i" :value="bt">
                            {{ $t(bt) }}
                        </option>
                    </select>
                </div>
                <span class="widgetWizardErrorMessage" v-show="showErrors && !benchmarkTypeValid">{{
                    $t("REQUIRED")
                }}</span>
            </div>
        </div>

        <!-- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -->
        <div
            v-show="
                !widgetTemplateProperty.benchmark ||
                widgetTemplateProperty.benchmarkType === 'BENCHMARK_CUSTOM'
            "
        >
            <div class="widgetWizardDataSource input-group">
                <label>
                    {{ $t("SET_DATA_REQUIRED") }}
                </label>

                <div class="query-builder-wrapper">
                    <data-set-section
                        :json-format.sync="widgetTemplateProperty.jsonFormat"
                        :query.sync="widgetTemplateProperty.query"
                        :native-query-enabled="nativeQueryEnabled"
                        :show-errors="showErrors"
                        @queryError="setQueryError"
                        ref="dataSetSection"
                    >
                    </data-set-section>
                </div>
                <span class="widgetWizardErrorMessage" v-show="showErrors && !queryValid">{{
                    $t("INVALID_QUERY")
                }}</span>
            </div>

            <div v-if="columns.length > 0">
                <span class="test-border" />
                <label class="ww-label">
                    {{ $t(widgetTemplateType === "TABLE" ? "COLUMNS_REQUIRED" : "AXES_REQUIRED") }}
                </label>
                <axis-property
                    v-if="xAxisProperty != null"
                    :axis-property="xAxisProperty"
                    :key="xAxisProperty.vueKey"
                    :axis-labels="axisLabels"
                    :axis-colors="axisColors"
                    :widget-template-type="widgetTemplateType"
                    :columns="columns"
                    :limit-display-types="limitDisplayTypes"
                    :is-benchmark="widgetTemplateProperty.benchmark"
                    :show-errors="showErrors"
                    :ref="'axisProperty-' + xAxisProperty.vueKey"
                    @setDisplayTypeForAxisProperties="
                        $emit('setDisplayTypeForAxisProperties', $event)
                    "
                    @setColorForAxisProperties="$emit('setColorForAxisProperties')"
                />
                <div
                    v-show="
                        widgetTemplateType !== 'SINGLE_VALUE' &&
                        widgetTemplateType !== 'GAUGE' &&
                        widgetTemplateType !== 'MOOD_MONITOR_GAUGE'
                    "
                    class="y-axis-properties"
                >
                    <button
                        type="button"
                        class="query-button btn btn-sm btn-success"
                        @click="addAxis('Y')"
                    >
                        {{ $t(widgetTemplateType ? "ADD_Y_AXIS" : "ADD_COLUMN") }}
                    </button>

                    <div
                        class="added-y-axis"
                        v-for="axisProperty in yAxisProperties"
                        :key="axisProperty.vueKey"
                    >
                        <axis-property
                            :axis-property="axisProperty"
                            :axis-labels="axisLabels"
                            :axis-colors="axisColors"
                            :widget-template-type="widgetTemplateType"
                            :columns="columns"
                            :limit-display-types="limitDisplayTypes"
                            :is-benchmark="widgetTemplateProperty.benchmark"
                            :show-errors="showErrors"
                            :ref="'axisProperty-' + axisProperty.vueKey"
                            @setDisplayTypeForAxisProperties="
                                $emit('setDisplayTypeForAxisProperties', $event)
                            "
                            @setColorForAxisProperties="$emit('setColorForAxisProperties')"
                        />
                        <button
                            v-if="yAxisProperties.length > 1"
                            type="button"
                            class="query-button-grey btn btn-sm btn-danger"
                            @click="removeAxis(axisProperty)"
                        >
                            {{ $t(widgetTemplateType ? "REMOVE_AXIS" : "REMOVE_COLUMN") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-show="
                !widgetTemplateProperty.benchmark ||
                widgetTemplateProperty.benchmarkType === 'BENCHMARK_CUSTOM'
            "
        >
            <span class="test-border" />

            <div class="input-group available-for">
                <label>{{ $t("RESTRICT_BY_NUM_OR_RESPONDENTS") }}</label>
                <div>
                    <input
                        type="radio"
                        :value="true"
                        v-model="widgetTemplateProperty.restrictByNumOfRespondents"
                        :id="'restrict-on-' + widgetTemplateProperty.vueKey"
                    />
                    <label
                        class="labelNormalText"
                        :for="'restrict-on-' + widgetTemplateProperty.vueKey"
                    >
                        <span>{{ $t("YES") }}</span>
                    </label>
                </div>
                <div>
                    <input
                        type="radio"
                        :value="false"
                        v-model="widgetTemplateProperty.restrictByNumOfRespondents"
                        :id="'restrict-off-' + widgetTemplateProperty.vueKey"
                    />
                    <label
                        class="labelNormalText"
                        :for="'restrict-off-' + widgetTemplateProperty.vueKey"
                    >
                        <span>{{ $t("NO") }}</span>
                    </label>
                </div>
            </div>
        </div>

        <div
            v-show="
                !(
                    widgetTemplateProperty.benchmark ||
                    widgetTemplateType === 'SINGLE_VALUE' ||
                    widgetTemplateType === 'MOOD_MONITOR_GAUGE'
                )
            "
            v-if="widgetTemplateProperty.range != null"
            class="y-axis-scale-range"
        >
            <label class="ww-label">{{ $t("SCALE_RANGE") }}</label>

            <label>{{ $t("BEGINNING") }}</label>

            <input
                :class="[
                    { widgetWizardInputError: showErrors && !rangeMinValid },
                    'generic-textfield',
                ]"
                type="number"
                step="0.01"
                v-model="range.min"
                ref="rangeMin"
                @input="rangeMinChanged"
            />

            <label>{{ $t("END") }}</label>

            <input
                :class="[
                    { widgetWizardInputError: showErrors && !rangeMaxValid },
                    'generic-textfield',
                ]"
                type="number"
                step="0.01"
                v-model="range.max"
                ref="rangeMax"
                @input="rangeMaxChanged"
            />

            <label
                v-show="
                    widgetTemplateType !== 'GAUGE' && widgetTemplateType !== 'MOOD_MONITOR_GAUGE'
                "
                >{{ $t("STEP") }}</label
            >

            <input
                :class="[
                    { widgetWizardInputError: showErrors && !rangeStepValid },
                    'generic-textfield',
                ]"
                v-show="
                    widgetTemplateType !== 'GAUGE' && widgetTemplateType !== 'MOOD_MONITOR_GAUGE'
                "
                type="number"
                step="0.01"
                v-model="range.step"
                ref="rangeStep"
                @input="rangeStepChanged"
            />

            <span v-show="showErrors && !rangeMinValid" class="widgetWizardErrorMessage">{{
                $t("DASHBOARD_APP_VALUE_MUST_BE_BETWEEN_BEGINNING", {
                    start: "-99 999 999.99",
                    end: "99 999 9999.99",
                })
            }}</span>

            <span v-show="showErrors && !rangeMaxValid" class="widgetWizardErrorMessage">{{
                $t("DASHBOARD_APP_VALUE_MUST_BE_BETWEEN_END", {
                    start: "-99 999 999.99",
                    end: "99 999 9999.99",
                })
            }}</span>

            <span v-show="showErrors && !rangeStepValid" class="widgetWizardErrorMessage">{{
                $t("DASHBOARD_APP_VALUE_MUST_BE_BETWEEN_STEP", {
                    start: "-50 000 000.00",
                    end: "500 000 000.00",
                })
            }}</span>
        </div>

        <div v-show="widgetTemplateProperty.benchmark" class="clear-benchmark">
            <input
                class="query-button"
                type="button"
                :value="$t('CLEAR')"
                @click="$emit('clear')"
            />
        </div>
    </form>
</template>

<script>
/* eslint-disable no-plusplus */
import { axisService, queryService } from "@/services/Evaluation";

import util from "@/helpers/evaluation/util";
import DataSetSection from "./DataSetSection.vue";
import AxisProperty from "./AxisProperty.vue";

export default {
    name: "WidgetTemplateProperty",
    mixins: [util],
    props: [
        "mainWidgetTemplateProperty",
        "widgetTemplateProperty",
        "widgetTemplateType",
        "nativeQueryEnabled",
        "benchmarkEnabled",
        "displayType",
        "axisLabels",
        "axisColors",
        "showErrors",
    ],
    components: {
        DataSetSection,
        AxisProperty,
    },
    data() {
        return {
            benchmarkTypes: [
                "BENCHMARK_ALL",
                "BENCHMARK_LOCATION",
                "BENCHMARK_ORGANIZATION",
                "BENCHMARK_CUSTOM",
            ],
            columns: [],
            vueKeyCounter: 0,
            queryError: false,
            queryColumnsLoading: false,
            range: {
                min: null,
                max: null,
                step: null,
            },
            rangeMinValid: true,
            rangeMaxValid: true,
            rangeStepValid: true,
        };
    },
    computed: {
        widgetTemplatePropertyQuery() {
            return this.widgetTemplateProperty ? this.widgetTemplateProperty.query : "";
        },
        limitDisplayTypes() {
            return this.yAxisProperties.length > 1 || this.benchmarkEnabled;
        },
        xAxisProperty() {
            const xAxisProperties = this.widgetTemplateProperty.axisProperties.filter(
                (axisProperty) => axisProperty.axis === "X"
            );
            return xAxisProperties.length > 0 ? xAxisProperties[0] : null;
        },
        yAxisProperties() {
            return this.widgetTemplateProperty.axisProperties.filter(
                (axisProperty) => axisProperty.axis === "Y"
            );
        },
        benchmarkLabelValid() {
            return (
                !this.benchmarkEnabled ||
                !this.widgetTemplateProperty.benchmark ||
                (this.widgetTemplateProperty.label != null &&
                    this.widgetTemplateProperty.label !== "")
            );
        },
        benchmarkTypeValid() {
            return (
                !this.benchmarkEnabled ||
                !this.widgetTemplateProperty.benchmark ||
                (this.widgetTemplateProperty.benchmarkType != null &&
                    this.widgetTemplateProperty.benchmarkType !== "")
            );
        },
        queryValid() {
            return !this.widgetTemplateProperty.benchmark ||
                this.widgetTemplateProperty.benchmarkType === "BENCHMARK_CUSTOM"
                ? this.columns.length > 0
                : true;
        },
    },
    created() {
        this.getQueryColumns();

        $.each(this.widgetTemplateProperty.axisProperties, (index, axisProperty) => {
            axisProperty.vueKey = this.vueKeyCounter++;
        });
        if (this.widgetTemplateProperty.axisProperties.length <= 0) {
            this.addAxis("X");
            this.addAxis("Y");
        }
    },
    methods: {
        setQueryError(error) {
            this.queryError = error;
        },
        getQueryColumns() {
            let operationFlag = false;
            let parsed = {};

            if (this.widgetTemplateProperty.query && !this.queryError) {
                // We check if the query object is empty
                if (!this.isEmptyObject(this.widgetTemplateProperty.query)) {
                    // We try parsing the object as a JSON
                    try {
                        parsed = JSON.parse(this.widgetTemplateProperty.query);
                    } catch (e) {
                        // If we are not successful - that means that the query is present - we set the flag to true
                        operationFlag = true;
                    }

                    // Regardless of successfully parsing the JSON we check if the new object has any queries
                    if (
                        parsed.queries &&
                        parsed.queries.length > 0 &&
                        parsed.queries[0].select.length > 0
                    ) {
                        let selectObj;
                        for (let i = 0; i < parsed.queries[0].select.length; i++) {
                            selectObj = parsed.queries[0].select[i];

                            // Check if at least one "select" has defined column or expression
                            // eslint-disable-next-line no-prototype-builtins
                            if (
                                ("column" in selectObj && selectObj.column !== "") ||
                                // eslint-disable-next-line no-prototype-builtins
                                (selectObj.hasOwnProperty("expression") &&
                                    selectObj.expression !== "")
                            ) {
                                operationFlag = true;
                                break;
                            }
                        }
                    }
                }

                if (operationFlag) {
                    const query = {
                        value: this.widgetTemplateProperty.query,
                        jsonQuery: this.widgetTemplateProperty.jsonFormat,
                    };
                    queryService
                        .getQueryColumns(query)
                        .then((response) => {
                            this.columns = response.data;
                            this.queryColumnsLoading = false;
                        })
                        .catch(() => {
                            this.columns = [];
                            this.queryColumnsLoading = false;
                        });
                } else {
                    this.columns = [];
                    this.queryColumnsLoading = false;
                }
            } else {
                this.queryColumnsLoading = false;
            }
        },
        addAxis(axis) {
            const axisProperty = {
                axis,
                displayType: this.displayType ? this.displayType : "NONE",
                label: null,
                columnIndex: null,
                numberOfDecimals: null,
                axisColor: null,
                vueKey: this.vueKeyCounter++,
            };
            if (axis === "Y") {
                axisProperty.axisColor = axisService.defineColor(axisProperty.axisColor);
            }
            this.widgetTemplateProperty.axisProperties.push(axisProperty);
        },
        removeAxis(axis) {
            this.widgetTemplateProperty.axisProperties.splice(
                this.widgetTemplateProperty.axisProperties.indexOf(axis),
                1
            );
        },
        benchmarkTypeChanged() {
            this.widgetTemplateProperty.query = this.mainWidgetTemplateProperty.query;
            this.widgetTemplateProperty.jsonFormat = this.mainWidgetTemplateProperty.jsonFormat;
            this.widgetTemplateProperty.axisProperties = this.copyObject(
                this.mainWidgetTemplateProperty.axisProperties
            );
            this.widgetTemplateProperty.restrictByNumOfRespondents =
                this.mainWidgetTemplateProperty.restrictByNumOfRespondents;

            const dataSetSection = this.getDataSetSection();
            if (dataSetSection) {
                this.$nextTick(() => {
                    dataSetSection.initialize();
                });
            }

            this.$emit("setColorForAxisProperties");
        },
        rangeMinChanged() {
            if (this.$refs.rangeMin && !this.$refs.rangeMin.validity.valid) {
                this.rangeMinValid = false;
            } else if (this.range.min === null || this.range.min === "") {
                this.rangeMinValid = true;
            } else {
                this.rangeMinValid = /^-?[0-9]{1,10}(\.[0-9]{1,2})?$/.test(this.range.min);
            }
            if (this.rangeMinValid) {
                this.widgetTemplateProperty.range.min = this.range.min;
            } else {
                this.widgetTemplateProperty.range.min = null;
            }
        },
        rangeMaxChanged() {
            if (this.$refs.rangeMax && !this.$refs.rangeMax.validity.valid) {
                this.rangeMaxValid = false;
            } else if (this.range.max === null || this.range.max === "") {
                this.rangeMaxValid = true;
            } else {
                this.rangeMaxValid = /^-?[0-9]{1,10}(\.[0-9]{1,2})?$/.test(this.range.max);
            }
            if (this.rangeMaxValid) {
                this.widgetTemplateProperty.range.max = this.range.max;
            } else {
                this.widgetTemplateProperty.range.max = null;
            }
        },
        rangeStepChanged() {
            if (this.$refs.rangeStep && !this.$refs.rangeStep.validity.valid) {
                this.rangeStepValid = false;
            } else if (this.range.step === null || this.range.step === "") {
                this.rangeStepValid = true;
            } else {
                this.rangeStepValid = /^-?[0-9]{1,9}(\.[0-9]{1,2})?$/.test(this.range.step);
            }
            if (this.rangeStepValid) {
                this.widgetTemplateProperty.range.step = this.range.step;
            } else {
                this.widgetTemplateProperty.range.step = null;
            }
        },
        getDataSetSection() {
            let dataSetSection;
            if (this.$refs.dataSetSection) {
                // eslint-disable-next-line no-nested-ternary
                dataSetSection = Array.isArray(this.$refs.dataSetSection)
                    ? this.$refs.dataSetSection.length > 0
                        ? this.$refs.dataSetSection[0]
                        : null
                    : this.$refs.dataSetSection;
            }
            return dataSetSection;
        },
        isValid() {
            if (
                !this.queryValid ||
                !this.benchmarkLabelValid ||
                !this.benchmarkTypeValid ||
                !this.rangeMinValid ||
                !this.rangeMaxValid ||
                !this.rangeStepValid
            ) {
                return false;
            }

            if (
                !this.widgetTemplateProperty.benchmark ||
                this.widgetTemplateProperty.benchmarkType === "BENCHMARK_CUSTOM"
            ) {
                const dataSetSection = this.getDataSetSection();
                if (dataSetSection && !dataSetSection.isValid()) {
                    return false;
                }

                let axisPropertiesValid = true;
                $.each(this.$refs, (key, ref) => {
                    if (key.indexOf("axisProperty-") === 0) {
                        // eslint-disable-next-line no-nested-ternary
                        const axisProperty = Array.isArray(ref)
                            ? ref.length > 0
                                ? ref[0]
                                : null
                            : ref;
                        if (axisProperty && !axisProperty.isValid()) {
                            axisPropertiesValid = false;
                            return false;
                        }
                    }
                });
                return axisPropertiesValid;
            }

            return true;
        },
    },
    watch: {
        widgetTemplatePropertyQuery(newValue, oldValue) {
            if (newValue !== oldValue && !this.queryColumnsLoading) {
                this.queryColumnsLoading = true;
                setTimeout(this.getQueryColumns, 500);
            }
        },
    },
};
</script>
