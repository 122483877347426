<template>
    <div class="queryBuilderSection query-builder">
        <div class="group-conditions">
            <query-builder-group
                :values="values"
                :can-remove="canRemove"
                @remove="$emit('remove')"
                @addCondition="addCondition"
                @addGroup="addGroup"
            />
        </div>

        <div class="group-conditions">
            <div v-for="(rule, index) in values.value" :key="rule.vueKey" class="condition">
                <div v-if="rule.type === 'booleanOperation'">
                    <query-builder-where
                        :values="rule"
                        :all-columns="allColumns"
                        :selected-columns="selectedColumns"
                        :can-remove="true"
                        @remove="removeGroup(index)"
                    />
                </div>
                <div v-else>
                    <query-builder-condition
                        :rule="rule"
                        :all-columns="allColumns"
                        @remove="removeCondition(index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-plusplus */
import QueryBuilderGroup from "./rules/QueryBuilderGroup.vue";
import QueryBuilderCondition from "./rules/QueryBuilderCondition.vue";

export default {
    name: "QueryBuilderWhere",
    props: ["values", "allColumns", "selectedColumns", "canRemove"],
    components: {
        QueryBuilderGroup,
        QueryBuilderCondition,
    },
    data() {
        return {
            vueKeyCounter: 0,
        };
    },
    created() {
        this.values.value.forEach((value) => {
            if (value.type === "expression") {
                value.column = this.addBacktick(value.column);
            }
            value.vueKey = this.vueKeyCounter++;
        });
    },
    methods: {
        addCondition() {
            this.values.value.push({
                type: "expression",
                column: this.addBacktick(this.selectedColumns[0].tableName),
                operator: "=",
                value: "",
                vueKey: this.vueKeyCounter++,
            });
        },
        addGroup() {
            this.values.value.push({
                type: "booleanOperation",
                operation: "AND",
                value: [],
                vueKey: this.vueKeyCounter++,
            });
        },
        removeGroup(index) {
            this.values.value.splice(index, 1);
        },
        removeCondition(index) {
            this.values.value.splice(index, 1);
        },
        addBacktick(column) {
            const columnParts = column.split(".");
            if (columnParts[1] && columnParts[1].indexOf("`") <= -1) {
                column = `${columnParts[0]}.\`${columnParts[1]}\``;
            }
            return column;
        },
    },
};
</script>
