<template>
    <div class="condition custom-condition">
        <div class="form-inline">
            <div class="form-inline-title">{{ $t("ORDER_BY") }}</div>
            <div class="select-wrapper select-arrow-down">
                <select
                    v-model="selectedColumn"
                    @change="columnChanged"
                    class="generic-select form-control input-sm"
                >
                    <option value="" disabled>{{ $t("COLUMN") }}</option>
                    <option
                        v-for="column in selectedColumns"
                        :key="columnValue(column.tableName)"
                        :value="columnValue(column.tableName)"
                    >
                        {{ column.displayText }}
                    </option>
                </select>
            </div>
            <div class="select-wrapper select-arrow-down">
                <select v-model="orderBy.direction" class="generic-select form-control input-sm">
                    <option value="" disabled>{{ $t("DIRECTION") }}</option>
                    <option v-for="(direction, i) in directions" :key="i" :value="direction.name">
                        {{ direction.name }}
                    </option>
                </select>
            </div>
            <remove-button @click="$emit('remove')" />
        </div>
    </div>
</template>

<script>
import QueryBuilderRemoveButton from "../buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderOrderBy",
    props: ["orderBy", "selectedColumns"],
    components: {
        "remove-button": QueryBuilderRemoveButton,
    },
    data() {
        return {
            selectedColumn: "",
            directions: [{ name: "ASC" }, { name: "DESC" }],
        };
    },
    created() {
        // this is needed because older widget templates don't have lowercased column value
        this.selectedColumn = this.columnValue(this.orderBy.column);
    },
    methods: {
        columnValue(column) {
            return column.toLowerCase();
        },
        columnChanged() {
            $.each(this.selectedColumns, (index, column) => {
                if (this.selectedColumn === this.columnValue(column.tableName)) {
                    this.orderBy.column = column.tableName;
                    return false;
                }
            });
        },
    },
};
</script>
