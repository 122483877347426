<template>
    <div class="form-inline">
        <div class="form-inline-title">{{ $t("GROUP") }}</div>
        <div class="select-wrapper select-arrow-down">
            <select
                v-model="values.operation"
                class="generic-select form-control input-sm select-wrapper"
            >
                <option value="" disabled>{{ $t("OPERATOR") }}</option>
                <option v-for="(operator, i) in operators" :key="i" :value="operator.name">
                    {{ operator.name }}
                </option>
            </select>
        </div>
        <add-button label="CONDITION" @click="$emit('addCondition')" />
        <add-button label="GROUP" @click="$emit('addGroup')" />
        <remove-button
            v-if="canRemove"
            @click="$emit('remove')"
            class="query-button-grey-margin-bottom"
        />
    </div>
</template>

<script>
import QueryBuilderAddButton from "../buttons/QueryBuilderAddButton.vue";
import QueryBuilderRemoveButton from "../buttons/QueryBuilderRemoveButton.vue";

export default {
    name: "QueryBuilderGroup",
    props: ["values", "canRemove"],
    components: {
        "add-button": QueryBuilderAddButton,
        "remove-button": QueryBuilderRemoveButton,
    },
    data() {
        return {
            operators: [{ name: "AND" }, { name: "OR" }, { name: "NOT" }],
        };
    },
};
</script>
