<template>
    <button type="button" @click="$emit('click')" class="query-button-grey">
        <svg
            class="remove-button"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 56 56"
            style="enable-background: new 0 0 56 56"
            xml:space="preserve"
        >
            <path
                class="st0"
                d="M5.3,0.3l50.5,50.5c0.4,0.4,0.4,1,0,1.4l-3.5,3.5c-0.4,0.4-1,0.4-1.4,0L0.3,5.3c-0.4-0.4-0.4-1,0-1.4l3.5-3.5
                C4.2-0.1,4.9-0.1,5.3,0.3z"
            />
            <path
                class="st0"
                d="M0.3,50.7L50.7,0.3c0.4-0.4,1-0.4,1.4,0l3.5,3.5c0.4,0.4,0.4,1,0,1.4L5.3,55.7c-0.4,0.4-1,0.4-1.4,0l-3.5-3.5
                C-0.1,51.8-0.1,51.1,0.3,50.7z"
            />
        </svg>
        <template v-if="label">
            &nbsp;
            <span>{{ $t(label) }}</span>
        </template>
    </button>
</template>

<script>
export default {
    name: "QueryBuilderRemoveButton",
    props: ["label"],
};
</script>
