<template>
    <div>
        <div class="widget-template-editor" v-loading="loading">
            <div class="widgetWizardAccordionItem">
                <a @click="settingsStepExpanded = !settingsStepExpanded">
                    <div class="panel-heading clearfix widgetWizardAccordionHeader">
                        <h3 id="widgetWizardSettingsStep" class="panel-title">
                            <span class="pull-left">{{ $t("WIDGET_WIZARD_SETTINGS_STEP") }}</span>
                            <span class="float-right">
                                <i class="material-icons tevreden-blue" style="margin: 12px 10px">{{
                                    settingsStepExpanded ? "expand_more" : "expand_less"
                                }}</i>
                            </span>
                        </h3>
                    </div>
                </a>
                <div v-show="settingsStepExpanded" class="widgetWizardAccordionBody">
                    <div class="widgetWizardTextField input-group">
                        <label>{{ $t("WIDGET_TITLE_REQUIRED") }}</label>
                        <input
                            :class="{
                                widgetWizardInputError:
                                    (showErrors || $v.widgetTemplate.defaultTitle.$dirty) &&
                                    !$v.widgetTemplate.defaultTitle.required,
                            }"
                            type="text"
                            class="generic-textfield form-control"
                            v-model.trim="$v.widgetTemplate.defaultTitle.$model"
                            required
                            maxlength="128"
                        />
                        <span
                            v-if="
                                (showErrors || $v.widgetTemplate.defaultTitle.$dirty) &&
                                !$v.widgetTemplate.defaultTitle.required
                            "
                            class="widgetWizardErrorMessage"
                            >{{ $t("EMPTY_INPUT_FIELD") }}</span
                        >
                    </div>
                    <div class="widgetWizardTextField input-group">
                        <label>{{ $t("WIDGET_DESCRIPTION") }}</label>
                        <input
                            type="text"
                            class="generic-textfield form-control"
                            id="widget-description-new"
                            v-model="widgetTemplate.description"
                            maxlength="150"
                        />
                    </div>
                    <div class="input-group available-for">
                        <label>{{ $t("DISPLAY_TYPE") }}</label>
                        <div>
                            <div
                                v-for="(widgetType, key) in widgetTypes"
                                :key="key"
                                class="display-select-input-element"
                            >
                                <input
                                    class="display-select-input"
                                    type="radio"
                                    :id="'widget-type-' + widgetType"
                                    :name="'widget-type-' + widgetType"
                                    :value="widgetType"
                                    v-model="widgetTemplate.widgetTemplateType"
                                    @change="setViewBasedOnWidgetType"
                                />
                                <label class="labelNormalText" :for="'widget-type-' + widgetType">
                                    <span>{{ $t(widgetType) }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="input-group available-for" v-show="extraOptionsVisibility">
                        <label>{{ $t("BENCHMARK") }}</label>
                        <div>
                            <input
                                type="radio"
                                name="benchmark"
                                id="benchmark-enabled"
                                :value="true"
                                v-model="widgetTemplate.benchmark"
                                :disabled="!canEnableBenchmark"
                            />
                            <label for="benchmark-enabled" class="labelNormalText">
                                <span>{{ $t("ENABLE_BENCHMARK") }}</span>
                            </label>
                        </div>
                        <input
                            type="radio"
                            name="benchmark"
                            id="benchmark-disabled"
                            :value="false"
                            v-model="widgetTemplate.benchmark"
                        />
                        <label for="benchmark-disabled" class="labelNormalText">
                            <span>{{ $t("DISABLE_BENCHMARK") }}</span>
                        </label>
                    </div>
                    <div class="input-group available-for" v-show="extraOptionsVisibility">
                        <label>{{ $t("RESPONDENTS") }}</label>
                        <div>
                            <input
                                type="radio"
                                name="showRespondents"
                                id="showRespondents-enabled"
                                :value="true"
                                v-model="widgetTemplate.showRespondents"
                            />
                            <label for="showRespondents-enabled" class="labelNormalText">
                                <span>{{ $t("SHOW_RESPONDENTS") }}</span>
                            </label>
                        </div>
                        <input
                            type="radio"
                            name="showRespondents"
                            id="showRespondents-disabled"
                            :value="false"
                            v-model="widgetTemplate.showRespondents"
                        />
                        <label for="showRespondents-disabled" class="labelNormalText">
                            <span>{{ $t("DONT_SHOW_RESPONDENTS") }}</span>
                        </label>
                    </div>
                    <div class="widgetWizardTextField input-group">
                        <label>{{ $t("MIN_RESULTS") }}</label>
                        <input
                            type="text"
                            class="generic-textfield form-control"
                            v-model="widgetTemplate.minimumResults"
                        />
                    </div>
                    <div class="widgetWizardTextField input-group">
                        <label>{{ $t("TRANSLATE_LABELS") }}</label>
                        <Checkbox v-model="widgetTemplate.translateLabels" />
                    </div>
                </div>
            </div>

            <div class="widgetWizardAccordionItem">
                <div class="panel-heading clearfix widgetWizardAccordionHeader">
                    <a @click="dataStepExpanded = !dataStepExpanded">
                        <h3 id="widgetWizardDataStep" class="panel-title">
                            <span class="pull-left">{{ $t("WIDGET_WIZARD_DATA_STEP") }}</span>
                            <span class="float-right">
                                <i class="material-icons tevreden-blue" style="margin: 12px 10px">{{
                                    dataStepExpanded ? "expand_more" : "expand_less"
                                }}</i>
                            </span>
                        </h3>
                    </a>
                </div>
                <div v-show="dataStepExpanded" class="widgetWizardAccordionBody querybuilder">
                    <widget-template-property
                        :widget-template-property="widgetTemplate.widgetTemplateProperties[0]"
                        :axis-labels="axisLabels"
                        :axis-colors="axisColors"
                        :widget-template-type="widgetTemplate.widgetTemplateType"
                        :native-query-enabled="nativeQueryEnabled"
                        :benchmark-enabled="widgetTemplate.benchmark"
                        :display-type="displayType"
                        :show-errors="showErrors"
                        :ref="
                            'widgetTemplateProperty-' +
                            widgetTemplate.widgetTemplateProperties[0].vueKey
                        "
                        @setDisplayTypeForAxisProperties="setDisplayTypeForAxisProperties"
                        @setColorForAxisProperties="setColorForAxisProperties"
                    >
                    </widget-template-property>
                    <widget-template-option
                        :widget-template-option="widgetTemplate.widgetTemplateOptions[0]"
                        :widget-template-type="widgetTemplate.widgetTemplateType"
                    >
                    </widget-template-option>
                </div>
            </div>

            <div class="widgetWizardAccordionItem" v-show="widgetTemplate.benchmark">
                <div class="panel-heading clearfix widgetWizardAccordionHeader">
                    <a @click="benchmarkStepExpanded = !benchmarkStepExpanded">
                        <h3 id="widgetWizardBenchmarkStep" class="panel-title">
                            <span class="pull-left">{{ $t("WIDGET_WIZARD_BENCHMARK_STEP") }}</span>
                            <span class="float-right">
                                <i class="material-icons tevreden-blue" style="margin: 12px 10px">{{
                                    benchmarkStepExpanded ? "expand_more" : "expand_less"
                                }}</i>
                            </span>
                        </h3>
                    </a>
                </div>
                <div v-show="benchmarkStepExpanded" class="add-benchmark">
                    <button
                        v-show="widgetTemplate.widgetTemplateProperties.length < 3"
                        type="button"
                        class="query-button btn btn-sm btn-success"
                        @click="addWidgetTemplateProperty(true)"
                    >
                        {{ $t("ADD_BENCHMARK") }}
                    </button>

                    <div
                        v-for="widgetTemplateProperty in benchmarkTemplateProperties"
                        :key="widgetTemplateProperty.vueKey"
                        class="widgetWizardAccordionBody querybuilder benchmark-query-builder"
                    >
                        <widget-template-property
                            :main-widget-template-property="
                                widgetTemplate.widgetTemplateProperties[0]
                            "
                            :widget-template-property="widgetTemplateProperty"
                            :axis-labels="axisLabels"
                            :axis-colors="axisColors"
                            :widget-template-type="widgetTemplate.widgetTemplateType"
                            :native-query-enabled="nativeQueryEnabled"
                            :benchmark-enabled="widgetTemplate.benchmark"
                            :display-type="displayType"
                            :show-errors="showErrors"
                            :ref="'widgetTemplateProperty-' + widgetTemplateProperty.vueKey"
                            @clear="clearWidgetTemplateProperty(widgetTemplateProperty.vueKey)"
                            @setDisplayTypeForAxisProperties="setDisplayTypeForAxisProperties"
                            @setColorForAxisProperties="setColorForAxisProperties"
                        >
                        </widget-template-property>
                        <!--widget-template-option :widget-template-option="widgetTemplateProperty"
                                                   :widget-template-type="widgetTemplate.widgetTemplateType">
                        </widget-template-option-->
                        <button
                            type="button"
                            class="query-button-grey btn btn-sm btn-danger float-right remove-benchmark"
                            @click="removeWidgetTemplateProperty(widgetTemplateProperty.vueKey)"
                        >
                            {{ $t("REMOVE_BENCHMARK") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <widget-popup
            :visible="widgetPreviewVisible"
            :widget="widgetForPreview"
            @close="closeWidgetPreview"
            :preview="true"
            :fullScreen="false"
        ></widget-popup>

        <ConfirmPopup
            v-if="confirmDialogVisible"
            title="Warning"
            :message="`This widget is set for ${numberOfAffectedUsers} ${
                numberOfAffectedUsers === 1 ? 'user' : 'users'
            }. Are you sure you want to save <b>${widgetTemplate.defaultTitle}</b>?`"
            confirm-btn="Save"
            confirmButtonStyle="primary"
            typeToConfirmString="Confirm save"
            typeToConfirmInstruction="Type 'Confirm save' to save."
            :confirmBtnIsLoading="loading"
            @confirm="widgetOverrideConfirmed"
            @cancel="closeConfirmDialog"
        />
    </div>
</template>

<script>
/* eslint-disable no-plusplus */

import Vue from "vue";

import { maxLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { ConfirmPopup, Checkbox } from "@feedbackcompany/feedback-company-vue-components";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import { axisService, queryService, widgetService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";
import WidgetTemplateProperty from "@/components/Evaluation/Dashboards/widgetEditor/WidgetTemplateProperty.vue";
import WidgetTemplateOption from "@/components/Evaluation/Dashboards/widgetEditor/WidgetTemplateOption.vue";
import WidgetPopup from "@/components/Evaluation/Dashboards/widget/WidgetPopup.vue";

export default {
    name: "WidgetEditor",
    mixins: [util, validationMixin],
    components: {
        WidgetTemplateProperty,
        WidgetTemplateOption,
        WidgetPopup,
        ConfirmPopup,
        Checkbox,
    },
    data() {
        return {
            loading: false,
            fromWidgetLibrary: false,
            widgetTemplate: null,
            vueKeyCounter: 0,
            showErrors: false,

            settingsStepExpanded: true,
            dataStepExpanded: true,
            benchmarkStepExpanded: true,

            previousState: null,

            extraOptionsVisibility: true,

            axisLabels: [],
            axisColors: [],

            nativeQueryEnabled: false,
            displayType: null,

            confirmDialogVisible: false,
            numberOfAffectedUsers: 0,

            widgetPreviewVisible: false,
            widgetForPreview: null,

            benchmarkWidgetTemplatePropertiesStack: [],

            widgetTypes: ["GRAPH", "TABLE", "SINGLE_VALUE", "GAUGE", "MOOD_MONITOR_GAUGE"],
        };
    },
    computed: {
        role() {
            return "ROLE_SUPERADMIN";
        },
        currentLanguage() {
            return "en";
        },
        canEnableBenchmark() {
            return (
                this.widgetTemplate != null &&
                this.widgetTemplate.widgetTemplateProperties.length > 0 &&
                this.widgetTemplate.widgetTemplateProperties[0].axisProperties.length > 1 &&
                this.widgetTemplate.widgetTemplateProperties[0].axisProperties[1].displayType !==
                    "PIE_CHART"
            );
        },
        benchmarkTemplateProperties() {
            return this.widgetTemplate != null
                ? this.widgetTemplate.widgetTemplateProperties.filter(
                      (templateProperty) => templateProperty.benchmark
                  )
                : [];
        },
        widgetTemplateType() {
            return this.widgetTemplate != null ? this.widgetTemplate.widgetTemplateType : null;
        },
        benchmarkProperty() {
            return this.widgetTemplate != null ? this.widgetTemplate.benchmark : false;
        },
        mainWidgetTemplateProperty() {
            return this.widgetTemplate != null &&
                this.widgetTemplate.widgetTemplateProperties.length > 0
                ? JSON.stringify(this.widgetTemplate.widgetTemplateProperties[0])
                : "{}";
        },
        actions() {
            return [
                {
                    type: "button",
                    btnType: "primary",
                    label: this.$t("SAVE"),
                    disabled: false,
                    handler: this.saveChanges,
                },
                {
                    type: "button",
                    btnType: "primary",
                    label: "Back",
                    disabled: false,
                    handler: this.goBack,
                },
            ];
        },
    },
    validations: {
        widgetTemplate: {
            defaultTitle: {
                required,
                maxLength: maxLength(128),
            },
            description: {
                maxLength: maxLength(150),
            },
        },
    },
    created() {
        this.widgetTemplate = this.$storage.getItem("widgetToEdit");
        this.$store.commit("DashboardStore/setNavItems", [
            "DASHBOARD_APP_WIDGET_EDITOR",
            this.widgetTemplate ? "Edit widget" : "Create widget",
        ]);
        this.$store.commit("DashboardStore/setActionBarItems", this.actions);

        this.$store.commit("DashboardStore/setEditorDatabaseStructure", null);

        this.initAxisLabels();
        this.initAxisColors();

        if (this.widgetTemplate != null) {
            if (
                this.widgetTemplate.widgetTemplateType !== "SINGLE_VALUE" &&
                this.widgetTemplate.widgetTemplateType !== "GAUGE" &&
                this.widgetTemplate.widgetTemplateType !== "MOOD_MONITOR_GAUGE"
            ) {
                this.displayType =
                    this.widgetTemplate.widgetTemplateProperties[0].axisProperties[1].displayType;
            } else if (
                (this.widgetTemplate.widgetTemplateType === "GAUGE" ||
                    this.widgetTemplate.widgetTemplateType === "MOOD_MONITOR_GAUGE") &&
                this.widgetTemplate.widgetTemplateOptions.length === 0
            ) {
                this.widgetTemplate.widgetTemplateOptions.push({
                    key: "GAUGE_GRADIENT_OPTION",
                    value: true,
                });
            }
            $.each(
                this.widgetTemplate.widgetTemplateProperties,
                (index, widgetTemplateProperty) => {
                    widgetTemplateProperty.vueKey = this.vueKeyCounter++;
                }
            );
        } else {
            this.widgetTemplate = {
                adminsOnly: true,
                defaultTitle: "",
                description: "",
                minimumResults: 10,
                widgetTemplateType: "GRAPH",
                showRespondents: true,
                benchmark: false,
                widgetTemplateProperties: [this.getNewWidgetTemplateProperty(false)],
                widgetTemplateOptions: [],
            };
            this.displayType = "LINE_CHART";
        }

        this.fromWidgetLibrary = !!this.$storage.getItem("fromWidgetLibrary");
        if (this.fromWidgetLibrary) {
            this.$store.commit("DashboardStore/setActionBarItems", this.actions);
        }

        this.nativeQueryEnabled = true;

        this.getDatabaseStructure();

        this.setViewBasedOnWidgetType();
    },
    methods: {
        goBack() {
            if (this.fromWidgetLibrary) {
                this.$router.push({ name: "WidgetTemplates" });
            } else {
                this.$router.push(this.$storage.getItem("widgetLibraryPrevRoute"));
            }
        },
        initAxisLabels() {
            axisService
                .getAvailableAxisLabels()
                .then((response) => {
                    const axisLabels = response.data;
                    $.each(axisLabels, (index, axisLabel) => {
                        axisLabel.translatedLabelName = this.$t(axisLabel.labelName);
                    });
                    axisLabels.sort(this.objectSortFunc("translatedLabelName"));
                    this.axisLabels = axisLabels;
                })
                .catch(() => {
                    this.displayMessage(
                        this.$t("DASHBOARD_APP_ERROR_GETTING_AXIS_LABELS"),
                        "error"
                    );
                });
        },
        initAxisColors() {
            axisService
                .initAvailableColors()
                .then((availableColors) => {
                    this.axisColors = availableColors;
                })
                .catch(() => {
                    this.displayMessage(
                        this.$t("DASHBOARD_APP_ERROR_GETTING_AXIS_COLORS"),
                        "error"
                    );
                });
        },
        getDatabaseStructure() {
            const tables = [];
            const allColumns = [];

            queryService
                .getDatabaseStructure()
                .then((response) => {
                    response.data.forEach((table) => {
                        tables.push(table);
                        table.columns.forEach((column) => {
                            allColumns.push({
                                name: `${table.name}.\`${column}\``,
                                displayText: `${table.displayText}.\`${column}\``,
                            });
                        });
                    });

                    this.$store.commit("DashboardStore/setEditorDatabaseStructure", {
                        tables,
                        allColumnsFromTables: allColumns,
                    });
                })
                .catch(() => {
                    this.displayMessage(
                        this.$t("DASHBOARD_APP_ERROR_GETTING_DATABASE_STRUCTURE"),
                        "error"
                    );
                });
        },
        setViewBasedOnWidgetType() {
            if (
                this.widgetTemplate.widgetTemplateType === "SINGLE_VALUE" ||
                this.widgetTemplate.widgetTemplateType === "GAUGE" ||
                this.widgetTemplate.widgetTemplateType === "MOOD_MONITOR_GAUGE"
            ) {
                this.extraOptionsVisibility = false;

                // Save previous widget state, in case that user wants to switch back
                this.previousState = {};
                this.previousState.widgetTemplateProperties = this.copyObject(
                    this.widgetTemplate.widgetTemplateProperties
                );
                this.previousState.benchmark = this.widgetTemplate.benchmark;
                this.previousState.showRespondents = this.widgetTemplate.showRespondents;

                // Delete other widgetTemplateProperties, remove y axes
                this.widgetTemplate.benchmark = false;
                this.widgetTemplate.showRespondents = false;
                this.widgetTemplate.widgetTemplateProperties = new Array(
                    this.widgetTemplate.widgetTemplateProperties[0]
                );
                this.widgetTemplate.widgetTemplateProperties[0].axisProperties = new Array(
                    this.widgetTemplate.widgetTemplateProperties[0].axisProperties[0]
                );
                if (
                    this.widgetTemplate.widgetTemplateProperties[0].axisProperties[0]
                        .displayType === undefined
                ) {
                    this.widgetTemplate.widgetTemplateProperties[0].axisProperties[0].displayType =
                        "NONE";
                }
            } else {
                this.extraOptionsVisibility = true;

                if (this.previousState != null) {
                    this.widgetTemplate.benchmark = this.previousState.benchmark;
                    this.widgetTemplate.showRespondents = this.previousState.showRespondents;
                    this.widgetTemplate.widgetTemplateProperties = this.copyObject(
                        this.previousState.widgetTemplateProperties
                    );
                    this.previousState = null;
                }
                if (this.widgetTemplate.widgetTemplateProperties[0].axisProperties.length === 1) {
                    this.widgetTemplate.widgetTemplateProperties[0].axisProperties.push({
                        axis: "Y",
                        displayType: "LINE_CHART",
                    });
                }
            }
        },
        addWidgetTemplateProperty(benchmark) {
            this.widgetTemplate.widgetTemplateProperties.push(
                this.getNewWidgetTemplateProperty(benchmark)
            );
        },
        removeWidgetTemplateProperty(vueKey) {
            $.each(
                this.widgetTemplate.widgetTemplateProperties,
                (index, widgetTemplateProperty) => {
                    if (widgetTemplateProperty.vueKey === vueKey) {
                        this.widgetTemplate.widgetTemplateProperties.splice(index, 1);
                        return false;
                    }
                }
            );
        },
        clearWidgetTemplateProperty(vueKey) {
            $.each(
                this.widgetTemplate.widgetTemplateProperties,
                (index, widgetTemplateProperty) => {
                    if (widgetTemplateProperty.vueKey === vueKey) {
                        Vue.set(
                            this.widgetTemplate.widgetTemplateProperties,
                            index,
                            this.getNewWidgetTemplateProperty(
                                this.widgetTemplate.widgetTemplateProperties[index].benchmark
                            )
                        );
                        return false;
                    }
                }
            );
        },
        saveChanges() {
            if (!this.isValid()) {
                this.scrollToInvalidElement();
                this.displayMessage(this.$t("DASHBOARD_APP_WIDGET_TEMPLATE_NOT_VALID"), "error");
                return;
            }

            if (this.widgetTemplate.id) {
                dashboardApiClient
                    .get(`/widget-templates/${this.widgetTemplate.id}/affected-users`)
                    .then((response) => {
                        this.loading = false;
                        this.numberOfAffectedUsers = response.data.numberOfAffectedUsers;
                        if (this.numberOfAffectedUsers > 0) this.openConfirmDialog();
                        else this.storeChanges();
                    })
                    .catch(() => {
                        this.loading = false;
                        this.displayMessage(
                            this.$t("DASHBOARD_APP_WIDGET_TEMPLATE_AFFECTED_USERS_ERROR"),
                            "error"
                        );
                    });
            } else {
                this.storeChanges();
            }
        },
        storeChanges() {
            this.loading = true;
            const widgetTemplate = this.getWidgetTemplateForSubmit();
            widgetService
                .saveWidgetTemplate(widgetTemplate)
                .then(() => {
                    this.loading = false;
                    this.displayMessage(
                        `You've successfully saved widget template "${this.widgetTemplate.defaultTitle}".`,
                        "success"
                    );
                    this.goBack();
                })
                .catch(() => {
                    this.loading = false;
                    this.displayMessage(
                        this.$t("DASHBOARD_APP_WIDGET_TEMPLATE_SAVE_ERROR"),
                        "error"
                    );
                });
        },
        getWidgetTemplateForSubmit() {
            this.setColorForAxisProperties();
            const widgetTemplate = this.copyObject(this.widgetTemplate);
            this.removeVueKeys(widgetTemplate);
            $.each(widgetTemplate.widgetTemplateProperties, (index, widgetTemplateProperty) => {
                if (widgetTemplateProperty.jsonFormat) {
                    try {
                        const query = JSON.parse(widgetTemplateProperty.query);
                        this.removeVueKeys(query);
                        widgetTemplateProperty.query = JSON.stringify(query);
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                }
            });
            return widgetTemplate;
        },
        isValid() {
            this.showErrors = true;

            if (this.$v.$invalid) {
                return false;
            }

            let widgetTemplatePropertiesValid = true;
            $.each(this.$refs, (key, ref) => {
                if (key.indexOf("widgetTemplateProperty-") === 0) {
                    // eslint-disable-next-line no-nested-ternary
                    const widgetTemplateProperty = Array.isArray(ref)
                        ? ref.length > 0
                            ? ref[0]
                            : null
                        : ref;
                    if (widgetTemplateProperty && !widgetTemplateProperty.isValid()) {
                        widgetTemplatePropertiesValid = false;
                        return false;
                    }
                }
            });
            return widgetTemplatePropertiesValid;
        },
        getNewWidgetTemplateProperty(benchmark) {
            return {
                id: null,
                benchmark,
                benchmarkType: null,
                restrictByNumOfRespondents: true,
                label: null,
                query: '{"type":"setOperation","operator":"UNION","queries":[]}',
                jsonFormat: true,
                range: {
                    id: null,
                    min: null,
                    max: null,
                    step: null,
                },
                axisProperties: [],
                vueKey: this.vueKeyCounter++,
            };
        },
        openConfirmDialog() {
            this.confirmDialogVisible = true;
        },
        closeConfirmDialog() {
            this.confirmDialogVisible = false;
        },
        widgetOverrideConfirmed() {
            this.closeConfirmDialog();
            this.storeChanges();
        },
        openWidgetPreview() {
            if (!this.isValid()) {
                this.scrollToInvalidElement();
                this.displayMessage(this.$t("DASHBOARD_APP_WIDGET_TEMPLATE_NOT_VALID"), "error");
                return;
            }
            const widgetTemplate = this.getWidgetTemplateForSubmit();
            this.widgetForPreview = {
                widgetTemplate,
                title: widgetTemplate.defaultTitle,
            };
            this.widgetPreviewVisible = true;
        },
        closeWidgetPreview() {
            this.widgetPreviewVisible = false;
            this.widgetForPreview = null;
        },
        removeVueKeys(obj) {
            Object.keys(obj).forEach((key) => {
                if (key === "vueKey") {
                    delete obj[key];
                } else {
                    const value = obj[key];
                    if (value && typeof value === "object") {
                        this.removeVueKeys(value);
                    }
                }
            });
        },
        scrollToInvalidElement() {
            this.$nextTick(() => {
                try {
                    const invalidElements = $(".widgetWizardErrorMessage");
                    let firstInvalidElement = null;
                    invalidElements.each((i, el) => {
                        if ($(el).is(":visible")) {
                            firstInvalidElement = el;
                            return false;
                        }
                    });
                    if (firstInvalidElement == null && invalidElements.length > 0) {
                        // eslint-disable-next-line prefer-destructuring
                        firstInvalidElement = invalidElements[0];
                    }
                    if (firstInvalidElement != null) {
                        $("html, body").animate(
                            {
                                scrollTop: $(firstInvalidElement).offset().top - 210,
                            },
                            1000
                        );
                    }
                } catch (e) {
                    // ignore
                }
            });
        },
        setDisplayTypeForAxisProperties(displayType) {
            this.displayType = displayType;

            $.each(
                this.widgetTemplate.widgetTemplateProperties,
                (index, widgetTemplateProperty) => {
                    $.each(widgetTemplateProperty.axisProperties, (axisIndex, axisProperty) => {
                        axisProperty.displayType = displayType;
                    });
                }
            );

            this.setColorForAxisProperties();
        },
        setColorForAxisProperties() {
            if (this.widgetTemplate.widgetTemplateProperties[0].axisProperties.length > 1) {
                const { displayType } =
                    this.widgetTemplate.widgetTemplateProperties[0].axisProperties[1];
                if (
                    this.widgetTemplate.benchmark &&
                    displayType !== "BAR_STACKED" &&
                    displayType !== "COLUMN_STACKED"
                ) {
                    axisService.setColorsNotInUse(this.getColorsNotInUse());
                    $.each(
                        this.widgetTemplate.widgetTemplateProperties,
                        (index, widgetTemplateProperty) => {
                            if (widgetTemplateProperty.benchmark) {
                                $.each(
                                    widgetTemplateProperty.axisProperties,
                                    (axisIndex, axisProperty) => {
                                        if (axisProperty.axis === "Y") {
                                            axisProperty.axisColor = axisService.defineColor(
                                                axisProperty.axisColor
                                            );
                                        }
                                    }
                                );
                            }
                        }
                    );
                }
            }
        },
        getColorsInUse() {
            const colorsInUse = [];
            $.each(
                this.widgetTemplate.widgetTemplateProperties,
                (index, widgetTemplateProperty) => {
                    if (!widgetTemplateProperty.benchmark) {
                        $.each(widgetTemplateProperty.axisProperties, (axisIndex, axisProperty) => {
                            if (axisProperty.axis === "Y" && axisProperty.axisColor) {
                                colorsInUse.push(axisProperty.axisColor);
                            }
                        });
                    }
                }
            );
            return colorsInUse;
        },
        getColorsNotInUse() {
            const colorsInUse = this.getColorsInUse();
            return this.axisColors.filter((color) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const i in colorsInUse) {
                    if (color.id === colorsInUse[i].id) {
                        return false;
                    }
                }

                return true;
            });
        },
    },
    watch: {
        currentLanguage() {
            const axisLabels = Object.assign([], this.axisLabels);
            $.each(axisLabels, (index, axisLabel) => {
                axisLabel.translatedLabelName = this.$t(axisLabel.labelName);
            });
            axisLabels.sort(this.objectSortFunc("translatedLabelName"));
            this.axisLabels = axisLabels;
        },
        widgetTemplateType(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === "GAUGE") {
                    this.widgetTemplate.widgetTemplateOptions = [
                        {
                            key: "GAUGE_GRADIENT_OPTION",
                            value: true,
                        },
                    ];
                } else {
                    this.widgetTemplate.widgetTemplateOptions = [];
                }
            }
        },
        benchmarkProperty(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (this.widgetTemplate.benchmark) {
                    if (this.benchmarkWidgetTemplatePropertiesStack.length > 0) {
                        $.each(
                            this.benchmarkWidgetTemplatePropertiesStack,
                            (index, widgetTemplateProperty) => {
                                this.widgetTemplate.widgetTemplateProperties.push(
                                    widgetTemplateProperty
                                );
                            }
                        );
                    }
                } else {
                    this.benchmarkWidgetTemplatePropertiesStack = [];
                    $.each(
                        this.widgetTemplate.widgetTemplateProperties,
                        (index, widgetTemplateProperty) => {
                            if (widgetTemplateProperty.benchmark) {
                                this.benchmarkWidgetTemplatePropertiesStack.push(
                                    this.copyObject(widgetTemplateProperty)
                                );
                            }
                        }
                    );
                    this.widgetTemplate.widgetTemplateProperties.splice(
                        1,
                        this.widgetTemplate.widgetTemplateProperties.length - 1
                    );
                }
            }
        },
        mainWidgetTemplateProperty(newValue, oldValue) {
            if (newValue !== oldValue) {
                for (let i = 1; i < this.widgetTemplate.widgetTemplateProperties.length; i++) {
                    if (
                        this.widgetTemplate.widgetTemplateProperties[i].benchmarkType !==
                        "BENCHMARK_CUSTOM"
                    ) {
                        this.widgetTemplate.widgetTemplateProperties[i].query =
                            this.widgetTemplate.widgetTemplateProperties[0].query;
                        this.widgetTemplate.widgetTemplateProperties[i].jsonFormat =
                            this.widgetTemplate.widgetTemplateProperties[0].jsonFormat;
                        this.widgetTemplate.widgetTemplateProperties[i].axisProperties =
                            this.copyObject(
                                this.widgetTemplate.widgetTemplateProperties[0].axisProperties
                            );
                        this.widgetTemplate.widgetTemplateProperties[i].restrictByNumOfRespondents =
                            this.widgetTemplate.widgetTemplateProperties[0].restrictByNumOfRespondents;
                        this.setColorForAxisProperties();
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../style_variables/style_variables.scss";
@import "../../../../assets/css/evaluation/widgetWizard.css";
@import "../../../../assets/css/evaluation/queryBuilder.css";

.widget-template-editor {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}
.labelNormalText {
    white-space: nowrap;
}
.widgetWizardAccordionItem {
    margin: 0 0 40px 0;
}
.widgetWizardAccordionItem {
    border-color: $grey_alabaster;
    border-radius: 8px;
}
.widgetWizardAccordionHeader {
    border-color: $grey_alabaster;
}
</style>
